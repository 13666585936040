<template>
	<div class="createPatient">
		<div class="necessary">
			<div class="necessary_title">以下是必填或必选项</div>
			<field title="患者称呼" type="1" placeholder="如:小王" ref="name"></field>
			<field title="性别" type="2" first="男" second="女" ref="sex"></field>
			<field title="癌种（病理诊断）" type="3" poptype="1" ref="illType" @diseaseClick="diseaseClick"></field>
			<field title="所在地区" type="3" poptype="3" ref="address"></field>
			<field title="最近一次就诊医院" type="3" poptype="2" ref="hospitalId"></field>
			<field title="就诊科室" type="1" placeholder="请填写" ref="departments"></field>
		</div> 
		<div class="selective ">
			<div class="selective_title">以下是选填项（提供资料越完善，报名成功率越高）</div>
			<field title="出生日期" type="3" poptype="4" ref="birthday"></field>
			<!-- <field title="疾病分期" type="3" poptype="5" ref="carcuStage"></field> -->
			<field title="是否手术" type="2" ref="isOperate"></field>
			<field title="是否化疗" type="2" ref="isChemothe" :isEditText="true" areaTips="请填写化疗方案（选填）"></field>
			<field title="靶向治疗" type="2" ref="isTarget" :isEditText="true" areaTips="请填写靶向药物（选填）"></field>
			<field title="免疫治疗" type="2" ref="immuno" :isEditText="true" areaTips="请填写免疫药物（选填）"></field>
			<field title="放射治疗" type="2" ref="radio"></field>
			<field title="其他治疗方式" type="2" ref="otherTreatment" :isEditText="true" areaTips="请填写您的治疗方式（选填）"></field>
		</div>
		<!-- <div class="levelBox">
			<div class="level_title">患者日常活动水平的最佳描述是：</div>
			<level ref="healthState"></level>
		</div> -->

		<div class="relation">
			<div class="relation_title">我和患者的关系：</div>
			<field title="关系" type="3" poptype="6" ref="relate"></field>
		</div>
		<div class="commitBtn" @click="commit">
			填写完成，保存
		</div>
	</div>
</template>

<script>
	import {
		Button,
		Toast,
	} from 'vant';
	import field from '@/components/patient/field';
	import level from '@/components/patient/level';
	import patientApi from '@/api/patient/patientApi.js'
	export default {
		components: {
			Button,
			field,
			level,
			[Toast.name]: Toast,
		},
		data() {
			return {
				disable:false,
				data: {
					name: '', //患者称呼
					sex: '', //性别1男 2女
					illType: '', //癌种类型
					projectId: this.$route.query.projectId,
					projectName: this.$route.query.projectName,
                    ddMemberId: this.$route.query.ddMemberId
				},

			}
		},
		methods: {
			//子组件调用这个组件
			diseaseClick(illId) {
				// this.$refs.carcuStage.setCarcuStage(illId)
			},
			//过滤空格
			trim(str) {
				return str.replace(/(^\s*)|(\s*$)/g, "");
			},
			//弹出提示
			toast(title){
				Toast({
					message: title,
					position: 'bottom'
				});
			},
			//判断必填项
			required(data) {
				if(this.trim(data.name)==""){
					this.toast("请输入患者称呼");
					return false;
				}else if(this.$refs.sex.start==false){
					this.toast("请选择性别");
					return false;
				}else if(this.trim(data.illType)==""){
					this.toast("请选择癌种");
					return false;
				}else if(this.trim(data.address)==""){
					this.toast("请选择所在地区");
					return false;
				}else if(data.hospitalId==""||data.hospitalId==null){
					this.toast("请选择最近一次就诊医院");
					return false;
				}else if(this.trim(data.departments)==""){
					this.toast("请输入科室");
					return false;
				}
				return true;
			},
			//点击提交保存
			commit() {
				const { projectId, projectName, ddMemberId} = this.data;
                console.log("push to create id:---"+ddMemberId);
				//已经提交过了 不允许重复点击
				if(this.disable) return
				let data = {
					name: this.$refs.name.tempName, //患者称呼
					sex: this.$refs.sex.getStatus(), //性别
					illType: this.$refs.illType.tempName, //癌种
					illId: this.$refs.illType.tempId, //癌种id
					address: this.$refs.address.tempName, //地址
					provinceId: this.$refs.address.city[0], //省份id
					cityId: this.$refs.address.city[1], //城市id
					hospitalId: this.$refs.hospitalId.tempId, //医院id
					departments: this.$refs.departments.tempName, //科室
					birthday: this.$refs.birthday.tempName, //生日
					// carcuStage: this.$refs.carcuStage.tempId, //分期
					isOperate: this.$refs.isOperate.getStatus(), //是否手术治疗
					isChemothe: this.$refs.isChemothe.getStatus(), //是否化疗
					chemothe: this.$refs.isChemothe.tempName, //化疗方案
					isTarget: this.$refs.isTarget.getStatus(), //是否靶向治疗
					targetMedicine: this.$refs.isTarget.tempName, //靶向药物
					immuno: this.$refs.immuno.getStatus(), //是否免疫治疗
					immunoMedic: this.$refs.immuno.tempName, //免疫药物
					radio: this.$refs.radio.getStatus(), //是否放射治疗
					isOtherTreatment: this.$refs.otherTreatment.getStatus(), //是否其他治疗
					otherTreatment: this.$refs.otherTreatment.tempName, //治疗方式
					// healthState: this.$refs.healthState.currentKey, //体力水平
					relate: this.$refs.relate.tempId, //我和患者的关系
				}
				// console.log(data)
				// console.log(this.required(data))				
				if(!this.required(data)) return
				this.disable = true
				//这里是报名入口的患者信息页面
				patientApi.save(data).then(rs => {
					//已经提交过一次了
					this.disable=false;
					if (rs.status == 1) {
						const {
							id,
							name
						} = rs.data;
						
						this.$router.push({
							path: '/apply',
							query: {
								projectId,
								projectName,
								inquiryId: id,
								inquiryName: name,
								ddMemberId: ddMemberId || ''
							}
						})
					}
				}).catch(err => {
					this.disable = false
				})

			},
		},
		mounted() {

		}
	}
</script>

<style lang="less" scoped="scoped">
	.createPatient {
		background: rgba(247, 247, 247, 1);
		height: auto;

		.necessary {
			.necessary_title {
				width: 100%;
				height: 44px;
				background: rgba(247, 247, 247, 1);
				font-size: 14px;
				font-weight: 400;
				color: rgba(208, 2, 27, 1);
				text-align: left;
				line-height: 44px;
				padding-left: 15px;
				box-sizing: border-box;
				// font-weight: bold;
			}

		}

		.selective {
			.selective_title {
				width: 100%;
				height: 44px;
				background: rgba(247, 247, 247, 1);
				font-size: 14px;
				font-weight: 400;
				color: #828284;
				text-align: left;
				line-height: 44px;
				padding-left: 15px;
				box-sizing: border-box;
				// font-weight: bold;
			}
		}

		.levelBox {
			.level_title {
				width: 100%;
				height: 44px;
				background: rgba(247, 247, 247, 1);
				font-size: 14px;
				font-weight: 400;
				color: #828284;
				text-align: left;
				line-height: 44px;
				padding-left: 15px;
				box-sizing: border-box;
				// font-weight: bold;
			}
		}

		.relation {
			.relation_title {
				width: 100%;
				height: 44px;
				background: rgba(247, 247, 247, 1);
				font-size: 14px;
				font-weight: 400;
				color: #828284;
				text-align: left;
				line-height: 44px;
				padding-left: 15px;
				box-sizing: border-box;
				// font-weight: bold;
			}
		}

		.commitBtn {
			width: 100%;
			height: 49px;
			background: rgba(0, 96, 194, 1);
			text-align: center;
			line-height: 49px;
			font-size: 17px;
			font-weight: 600;
			color: rgba(255, 255, 255, 1);
			margin-top: 50px;
		}
	}
</style>
